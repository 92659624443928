let count = 0; // Declare the variable 'count'
let savedPrompt = '';
export function isThisLoading(isLoading: boolean) {
    // console.log('isLoading: ', isLoading, ` test ` + `${count}`)
    count++;
    let promptCount = count
    if (count >= 1 && count <= 4) {
        autoPrompts(promptCount, savedPrompt);
    } else if (count == 5) {
        generateReport(promptCount, savedPrompt);
    }

}

const updatePromptTextArea = (textContent: any) => {
    console.log('f: ', count, ' text:', textContent)
    const promptTextArea = document.getElementById('promptTextArea') as HTMLTextAreaElement;
    if (promptTextArea) {
        promptTextArea.value = '';
        promptTextArea.value = textContent; // Include inputText value
    }
}

const triggerPromptButtonClick = (event?: Event) => {
    // Programmatically trigger button click
    const promptEnterButton = document.getElementById('promptEnter') as HTMLButtonElement | null;
    if (promptEnterButton) {
        promptEnterButton.click();
        if (event) {
            event.stopPropagation();
        }
    }
}

export function firstPrompt() {
    console.log('f: ', count);

    const loadingImage = document.getElementById('loadingImage') as HTMLElement;
    loadingImage.style.display = 'block';

    const textArea = document.getElementById('staffReportTextArea') as HTMLTextAreaElement;
    
    savedPrompt = textArea.value;
    const textContent = `Provide a summary of the ${savedPrompt} to include the key highlights related to the topic and the purpose of this report in relation to the action being proposed. You should write at least four to five sentences that summarizes the nature of the report so the reader has a clear understanding of the topic and the nature of the report, to include the specific request. Always start the section with "The staff is requesting that the Board of County Commissioners approve the ${savedPrompt}."\nReference the FY24-WashoeCounty-RefreshedStrategicPlan for the strategic objective. Always reference the specific Washoe County Strategic Objective being supported. Use this exact structure: Washoe County Strategic Objective supported by this item: {Washoe County Strategic Objective that aligns with this topic}\nConsider anticipated questions from elected officials in your response regarding this topic and address them in a summarized paragraph style response.`;

    // Usage with delay:
    setTimeout(() => {
        updatePromptTextArea(textContent);
    }, 50); // 50-millisecond delay

    // Usage with delay:
    setTimeout(() => {
        triggerPromptButtonClick();
        const questionInputContainer = document.querySelector('#chatInputWrapper');
        if (questionInputContainer) {
            questionInputContainer.classList.add('active');
        }

    }, 250); // 50-millisecond delay

}

export function autoPrompts(promptCount: number, mySavedPrompt: any) {
    // console.log('f: ', count);
    let textContent = '';

    const textArea = document.getElementById('staffReportTextArea') as HTMLTextAreaElement;

    // PREVIOUS ACTION
    if (promptCount == 1) {
        textContent = `Only research meeting minutes and provide a detailed list of all board of county commissioner actions related to the ${mySavedPrompt}. Include any stated motion, voting results, funding and any presentations made to the board. Include names. Sort by most recent meeting date and reference specific meeting minutes. Display in paragraph format. Sort by date`;
    }
    // BACKGROUND
    else if (promptCount == 2) {
        textContent = `Write a detailed background about the ${mySavedPrompt}. The background should include a number of sections using the below headers are suggestions but may be skipped, altered or added to for the specific analysis.  You should summarize the background in a logical manner to provide a clear understand of the topic from the beginning to today. Be thorough, complete, but also concise. Use paragraphs.
	
        **Historical Summary**: Provide a detailed summary of the topic to include overall intent or purpose, detail about the topic, historical events, known challenges or opportunities, the authorities of the organization related to the topic, legal considerations, ethical considerations, and community considerations.
         
        **Legal and Regulatory References**: Cite any relevant county codes (cite actual definition of the code), Nevada Revised Statutes (NRS) (cite actual definition of the NRS referenced), or other legal and regulatory frameworks that apply to the project. Explain how these laws influence the project's scope, execution, or objectives. Cite required notice and when (actual dates) and where (newspaper or otherwise) notice was made.
         
        **Master Plans and Strategic References**: Reference any master plans, strategic plans, or comprehensive plans that the project aligns with or supports. Describe how the project contributes to the goals and objectives outlined in these plans. `
    }
    // FISCAL IMPACT
    else if (promptCount == 3) {
        textContent = `Explain the financial implications of ${mySavedPrompt}, including the source of funds and how it fits into the larger budget or financial strategy for ${mySavedPrompt}.`
    }
    // POSSIBLE MOTION
    else if (promptCount == 4) {
        textContent = `Write a possible motion about the ${mySavedPrompt}. Use this exact structure: Should the Board agree with the staff's recommendation, a possible motion would be "Move to…". Use the language in the recommendation from the previous response.`;
    }
    // SUBJECT
    // else if (promptCount == 6) {
    //     textContent = `Repeat ${mySavedPrompt} exactly and nothing else. No summartization or paraphrasing.`;
    // }

    // console.log(mySavedPrompt)

    // Usage with delay:
    setTimeout(() => {
        updatePromptTextArea(textContent);
    }, 50); // 50-millisecond delay

    // Usage with delay:
    setTimeout(() => {
        triggerPromptButtonClick();
    }, 1000); // 50-millisecond delay

}

export const generateReport = (promptCount: number, mySavedPrompt: any) => {
    const selectedDivs = document.querySelectorAll('.content');
    const contentData: Record<string, string> = {};
    const targetDiv = document.getElementById('chatMessageStream') as HTMLElement;

    const superscriptPattern = /<sup\b[^>]*>([\s\S]*?)<\/sup>/gi;

    function removeHTMLBetweenSuperscript(selectedDivsText: string): string {
        const superscriptPattern = /<sup\b[^>]*>([\s\S]*?)<\/sup>/gi;
        return selectedDivsText.replace(superscriptPattern, '');
    }

    selectedDivs.forEach((div, index) => {
        const selectedDivs = div as HTMLElement;
        const selectedDivsHtml = selectedDivs.innerHTML;
        if (selectedDivsHtml !== null) {
            const cleanedText = removeHTMLBetweenSuperscript(selectedDivsHtml);
            contentData[index] = cleanedText.replaceAll('<p>', '<p style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<td>', '<td style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<li>', '<li style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;"><p style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt; margin: 0;">').replaceAll('</li>', '</p></li>').replaceAll('<strong>', '<strong style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h1>', '<h1 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h2>', '<h2 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h3>', '<h3 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h4>', '<h4 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">');
        }
    });

    // Usage with delay:
    setTimeout(() => {
        const StaffReportHTML = `
    <div class="chatMessageGpt">
        <div class="chatMessageId">
            <div class="answerContainer static-class selected" tabindex="0" id="printArea">
                <div class="answerText content">
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">STAFF REPORT</strong></p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">BOARD MEETING DATE</strong>:</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">TO</strong>: Board of County Commissioners</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">THROUGH</strong>:</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">SUBJECT</strong>: ${mySavedPrompt}</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">SUMMARY:</strong></p>${contentData[0]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">PREVIOUS ACTION</strong>:</p>${contentData[1]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">BACKGROUND</strong>:</p>${contentData[2]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">FISCAL IMPACT:</strong></p>${contentData[3]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">RECOMMENDATION:</strong></p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">${mySavedPrompt}</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">POSSIBLE MOTION:</strong></p>${contentData[4]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Attachments:</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Case Maps:</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Ordinance:</p>
                </div>
            </div>
            <div class="promptRepsonseInteractionContainer">
                <div class="promptRepsonseInteractionSaveButton" id="downloadAsWord" role="button" aria-label="Save button">Download as Word</div>
            </div>
        </div>
    </div>
    `
        const loadingImage = document.getElementById('loadingImage') as HTMLElement;
        loadingImage.style.display = 'none';
        targetDiv.insertAdjacentHTML('beforeend', StaffReportHTML);

        console.log(contentData, 'Completed!')
    }, 2000); // 50-millisecond delay

}