import { IconButton, Modal, PrimaryButton, Spinner, Stack, Text} from "@fluentui/react";
import {useDropzone} from 'react-dropzone';
import React, {useCallback, useState} from 'react';
import {checkIndexStatus, uploadFileToAzureBlobStorage} from '../../api';
import styles from "./FileUploadModal.module.css";

interface Props {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
}

export const FileUploadModal = ({isOpen, setOpen} : Props) => {
    const [isLoading, setLoading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState<string>();
    const [intervalId, setIntervalId] = useState<number | null>();
    const ALL_FILES_INDEXED_MESSAGE ='All files have been indexed.'
    const INDEXING_FILES_MESSAGE = 'Indexing files...'
    const closeModal = () => {
        setUploadMessage(undefined);
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setOpen(false);
    }

    const onDrop = useCallback((files: File[]) => {
        if (files.length === 0){
            return;
        }
        setLoading(true);
        const uploadPromises = files.map(file => uploadFileToAzureBlobStorage(file));
        Promise.all(uploadPromises)
            .then(() => {
                setUploadMessage("Files uploaded... Starting to index");
                //CALL METHODS IN EVERY 3 SECONDS
                const id = setInterval(() => {
                    checkIndexStatus().then(status => {
                        console.log(status);
                        if (status === 'inProgress') {
                            setUploadMessage(INDEXING_FILES_MESSAGE);
                        } else if (status === "success") {
                            setUploadMessage(ALL_FILES_INDEXED_MESSAGE);
                            clearInterval(id);
                            setIntervalId(null);
                        }
                    });
                }, 3000);
                setIntervalId(id);
            })
            .catch(error => {
                setUploadMessage('File upload failed...');
                console.error('Error uploading files:', error);
            }).finally(() => {
                setLoading(false);
            });
    }, [])


    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        noDragEventsBubbling: true,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        },
        onDrop: onDrop,
    });

    const getModalContent = () => {
        if (isLoading) {
            return <Spinner label="Uploading files..." ariaLive="assertive" labelPosition="right"/>
        }
        if (!!uploadMessage) {
            return <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                {uploadMessage === INDEXING_FILES_MESSAGE ?
                    <Spinner label={uploadMessage} ariaLive="assertive" labelPosition="right"/> : 
                    <Text className={styles.uploadMessage}>{uploadMessage}</Text>
                }
                {uploadMessage === ALL_FILES_INDEXED_MESSAGE && <PrimaryButton className={styles.okButton} ariaLabel="Close modal" onClick={() => closeModal()} text='Ok'/>}
            </Stack>
        }
        return <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <div  className={styles.dragText} aria-label='Drag & Drop Files Here, or Click to Upload. (Only pdf, doc and docx files are supported)'>
                <Text>Drag & Drop Files Here, or Click to Upload. (Only pdf, doc and docx files are supported)</Text>
            </div>
        </div>
    }

    return <Modal
        isOpen={isOpen}
        onDismiss={() => {
            if (!isLoading) {
                closeModal()
            }
        }}
        isBlocking={false}
        containerClassName={styles.uploadModalContainer}
    >
        <div className={styles.modalHeader}>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <Text variant="large">Upload File(s)</Text>
                <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close modal"
                    onClick={() => closeModal()}
                />
            </Stack>
        </div>

        <div className={styles.modalBody}>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
                {getModalContent()}
            </Stack>
        </div>
    </Modal>
}
