import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";

export const toggleNavDropdown = () => {
    const toggleNavDropdownButton = document.getElementById('headerSelectNav') as HTMLButtonElement | null;
    if (toggleNavDropdownButton) {
        toggleNavDropdownButton.classList.toggle('active');
    }
}

export const toggleParentBorder = () => {
    const parentBorder = document.getElementById('parentBorder') as HTMLElement | null;
    if (parentBorder) {
        parentBorder.style.borderBottomRightRadius = "0px";
    }
}
export const toggleParentBorderOff = () => {
    const parentBorder = document.getElementById('parentBorder') as HTMLElement | null;
    if (parentBorder) {
        parentBorder.style.borderBottomRightRadius = "8px";
    }
}

export const navContainer = () => {
    return (
        <div className={styles.headerSelectNav} >
            <div className={`${styles.headerSelectedNav} first-class`}
                onClick={() => toggleNavDropdown()}
                id="headerSelectNav"
            >
                Staff Report Writer
                <div className={`${styles.headerSelectedNavDropDown} second-class`} id="parentBorder" style={{ display: "none" }}>
                    <ul className={styles.dialogItems}>
                        <li className={styles.promptClickerTitle}>
                            Select Madison AI Assistant
                        </li>
                        <li className="promptClicker">
                            <a href="https://fresno.madisonai.com/">Citywide Knowledge Base</a>
                            <div className={styles.dialogItemsCheck}>
                                <input type="checkbox" className="checkbox" />
                                <label htmlFor="myCheckbox">
                                    <span className={styles.checkboxIcon}></span>
                                </label>
                            </div>
                            <div className={styles.navDescription}>Agendas, Meeting Details,  Minutes, and Budgets.</div>
                        </li>
                        <li className="promptClicker selected">
                            <a href="https://fresno-sr.madisonai.com/">Staff Report Writer</a>
                            <div className={styles.dialogItemsCheck}>
                                <input type="checkbox" className="checkbox" />
                                <label htmlFor="myCheckbox">
                                    <span className={styles.checkboxIcon}></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
