import { useState, useEffect, useRef } from "react";
import {IconButton, Stack, TextField} from "@fluentui/react";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import {FileUploadModal} from './FileUploadModal';

interface Props {
    onSend: (question: string, id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ onSend, placeholder, clearOnSend, conversationId }: Props) => {
    let [question, setQuestion] = useState<string>("");
    const [displayUploadModal, setDisplayUploadModal] = useState(false);

    const sendQuestion = () => {
        const textarea = document.getElementById("promptTextArea") as HTMLTextAreaElement;
        question = textarea.value

        if (!question.trim()) {
            return;
        }

        if (conversationId) {
            onSend(question, conversationId);
        } else {
            onSend(question);
        }

        if (clearOnSend) {
            setQuestion("");
        }

    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <IconButton
                iconProps={{ iconName: 'Attach', className: styles.whiteIcon }}
                id="uploadButton"
                styles={{
                    icon: { fontSize: 24},
                    root: {
                        backgroundColor: '#006D9B',
                        height:'50px',
                        width:'50px',
                        marginLeft: '10px',
                        marginTop: '10px',
                        borderRadius: '8px',
                        color: 'white !important'
                    },
                    rootHovered: {backgroundColor: '#0eb6e3'},
                    rootPressed: {backgroundColor: '#0eb6e3'}
                }}
                ariaLabel="Upload files"
                onClick={() => setDisplayUploadModal(true)}
            />
            <FileUploadModal isOpen={displayUploadModal} setOpen={(val) => setDisplayUploadModal(val)} />
            <TextField
                id='promptTextArea'
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            <div id='promptEnter' className={styles.questionInputSendButtonContainer}
                role="button"
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                <img src={Send} className={styles.questionInputSendButton} />
            </div>
            <div className={styles.questionInputBottomBorder} />
        </Stack>
    );
};

